import React from 'react'

const NewStrategy = () => {
    return (
        <div>
            <h1>Hello</h1>
        </div>
    )
}

export default NewStrategy
